import React, { useEffect, useMemo, useState } from 'react'
import { StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { Autocomplete, Paper, Popover, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { SelectWrapper } from 'features/analytics/chart/ui/charts/OrdersAndSales/ui/styled'
import { Query as ProductType } from 'shared/lib/generated/products/Api'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'
import { checkValue, isNotNil } from 'shared/lib/checkers'
import { dayTitle } from 'shared/lib/utils/DayTitle'
import { StyledChips } from 'shared/ui/styled/StyledChips'
import { PresetsSaveButton } from 'shared/ui/components/Table/ui/FiltersPresets/PresetsSaveButton'
import { PresetsList } from 'shared/ui/components/Table/ui/FiltersPresets/PresetsList'
import { useQuery } from '@apollo/client'
import { ProductPayload } from 'shared/api/pim/excel/products'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { ProductsOrderStatus } from 'shared/config'
import { GoodsTypeName } from 'shared/config/goods'

import {
  FilterWrapper,
  SliderWrapper,
  StyledAutocompleteInput,
  StyledChipsContainer,
  StyledSlider
} from '../styled'
import { FilterNames, IAvailableFilters } from '../../model/types'

interface ITableFilters {
  showOrderStatus?: boolean
  filtersPresetsKey?: string
  availableFilters?: IAvailableFilters
  queryContext: 'goods' | 'products'
  filtersListParams: any
  filtersQuery: any
}

const CustomPaper = (props) => <Paper style={{ width: 'max-content', maxWidth: '300px' }} {...props} />

export const TableFilter = ({
  filtersListParams,
  showOrderStatus,
  filtersPresetsKey,
  queryContext,
  filtersQuery
}: ITableFilters) => {
  const { searchObj } = useQueryParams({ arrayFormat: 'bracket', parseBooleans: true })
  const [ openedFilter, setOpenedFilter ] = useState<string | null>()

  const { searchString, inArchive } = searchObj

  const { data, previousData } = useQuery<ProductType, ProductPayload>(filtersQuery, {
    variables: {
      ...filtersListParams,
      excludeInArchive: !checkValue(inArchive),
      searchString: checkValue(searchString),
      ...(openedFilter && { [openedFilter]: [] })
    }, context: { apiName: queryContext } })

  const filtersData = useMemo(() => data || previousData, [data, previousData])

  const tableFilters = {
    ...filtersData?.[queryContext]?.filters,
    ...(showOrderStatus && {
      orderStatus: filtersData?.[queryContext]?.filters.orderStatus?.map(el => ({
        ...el,
        label: ProductsOrderStatus[el.value]
      })) || []
    }),
    ...(filtersData?.[queryContext]?.filters.goodsType && {
      goodsType: filtersData?.[queryContext]?.filters.goodsType?.map(el => ({
        ...el,
        label: GoodsTypeName[el.value]
      }))
    })
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [ sliderCount, setSliderCount ] = useState<number>(30)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    handleTableParams(filtersParameters)
  }
  
  const open = Boolean(anchorEl)
  
  const history = useHistory()

  const [filtersParameters, setFiltersParameters] = useState<any>({})

  useEffect(() => {
    const initialParams: any = {}
    Object.keys(filtersListParams).forEach((key) => {
      const value = filtersListParams[key]
      if (isNotNil(value)) {
        if (key === 'orderStatusInterval') {
          initialParams[key] = [{
            label: `${value} ${dayTitle(value)}`,
            value
          }]
        } else {
          initialParams[key] = Array.isArray(value)
            ? value.map((elem) => ({
              label: tableFilters[key]?.find((item) => item.value === elem)?.label || '',
              value: elem,
              count: tableFilters[key]?.find((item) => item.value === elem)?.count || null,
            }))
            : [{
              label: tableFilters[key]?.find((item) => item.value === value)?.label || '',
              value,
              count: tableFilters[key]?.find((item) => item.value === value)?.count || null,
            }]
        }
      }
    })
    setFiltersParameters(initialParams)
  }, [JSON.stringify(tableFilters), JSON.stringify(filtersListParams)])
  
  const allItemsDelete = () => {
    setFiltersParameters(prev => {
      const { ...rest } = prev
      Object.keys(rest).forEach((el) => {
        rest[el] = []
      })
      handleTableParams(rest)
      return rest
    })
  }

  useEffect(() => {
    if (Object.keys(searchObj).length === 0) {
      allItemsDelete()
    }
  }, [JSON.stringify(searchObj)])

  const onItemDelete = (item, filter) => () => {
    const newFilters = filtersParameters
    newFilters[filter] = newFilters[filter].filter(e => JSON.stringify(e) !== JSON.stringify(item))
    handleTableParams(newFilters)
    setFiltersParameters(newFilters)
  }

  const handleTableParams = (params) => {
    const parametersObject = {}
    // eslint-disable-next-line no-return-assign
    Object.keys(params).map(el => parametersObject[el] = params[el].map(elem => elem.value) )
    // eslint-disable-next-line consistent-return
    const historyStr = queryString.stringify(
      { ...searchObj, ...parametersObject },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    if (JSON.stringify(filtersListParams, (key, value) => {
      if (value === undefined) {
        return []
      } return value
    }) !== JSON.stringify(parametersObject)) {
      history.replace({ search: `?${historyStr}` })
    }
  }

  const showFiltersChips = isNotNil(filtersData) && (Object.values(filtersParameters) as any).find(el => el?.length > 0)

  const handleSearchParams = (params) => {
    const searchParams = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...searchParams, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace({ search: `?${historyStr}` })
  }

  
  return (
    <FilterWrapper>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={searchObj.searchString}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }}
        onChange={(e) => {
          debouncedSearch(() => handleSearchParams({ searchString: e.target.value }))
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }}/>
      {isNotNil(filtersData) &&
      <div style={{ display: 'flex', flexDirection: 'column' }} id="table-filter">
        <StyledFilterContainer>
          {Object.keys(tableFilters).map((key, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SelectWrapper key={index} width={(FilterNames[key]?.length * 8.17) + 60}>
              <Autocomplete
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                open={openedFilter === key}
                onFocus={() => setOpenedFilter(key)}
                onClose={() => setOpenedFilter(null)}
                options={tableFilters[key]}
                value={filtersParameters[key] || []}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                openText="Открыть"
                closeText="Закрыть"
                disableCloseOnSelect={true}
                disableClearable={true}
                multiple={true}
                size="small"
                PaperComponent={CustomPaper}
                isOptionEqualToValue={isOptionEqual}
                onBlur={() => handleTableParams(filtersParameters)}
                onChange={(event, value, reason, details) => {
                  if (value) {
                    setFiltersParameters(
                      prev =>({ ...prev, [key]: (typeof filtersListParams[key]) === 'object' ? value : [details!.option] }))
                  }
                  if (value?.length === 0) {
                    setFiltersParameters(prev =>({ ...prev, [key]: [] }))
                  }
                }}
                getOptionLabel={(option) => `${option.label} ${option.count || ''}` || ''}
                renderOption={(props: object, option: any) => (
                  <div {...props}>
                    {option.label}
                    {option.count &&
                    <div style={{ paddingLeft: '16px', marginLeft: 'auto', marginRight: '0' }}>
                      <span style={{ fontSize: '16px', color: '#00000099' }}>{option.count}</span>
                    </div>
                    }
                  </div>
                )}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={`${FilterNames[key]}`}/>
                )}
              />
            </SelectWrapper>
          ))}
          { showOrderStatus ?
            <>
              <Popover
                id="simle-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <SliderWrapper>
                  <StyledSlider
                    aria-label="Small steps"
                    defaultValue={sliderCount}
                    onChangeCommitted={(_ ,value) => {
                      setSliderCount(value as number)
                      setFiltersParameters(prev =>({ ...prev, orderStatusInterval: [{
                        label: `${value} ${dayTitle(value)}`,
                        value
                      }] }))
                    }}
                    size="small"
                    marks={true}
                    min={3}
                    max={30}
                    valueLabelDisplay="auto"
                  />
                  <div>
                    <Typography
                      variant="body2"
                    >
                      3 дня
                    </Typography>
                    <Typography
                      variant="body2"
                    >
                      30 дней
                    </Typography>
                  </div>
                </SliderWrapper>
              </Popover>
              <StyledAutocompleteInput onClick={handleClick}>
                Период динамики
              </StyledAutocompleteInput>
            </>
            :
            null
          }

          {filtersPresetsKey && <PresetsList filtersListParams={filtersListParams} filtersPresetsKey={filtersPresetsKey}/>}

        </StyledFilterContainer>
      
        {showFiltersChips &&
        <StyledChipsContainer> 
          <> 
            { Object.keys(filtersParameters).map((el) => (filtersParameters[el].map((elem) => (
              <StyledChips
                size="small" 
                key={elem.value} label={elem.label} onDelete={onItemDelete(elem, el)}/> 
            ))))}
            <StyledChips size="small"  label="Сбросить все" onClick={allItemsDelete}/>
            { filtersPresetsKey &&
              <PresetsSaveButton filtersListParams={filtersListParams} filtersPresetsKey={filtersPresetsKey}/>
            }
          </> 
        </StyledChipsContainer> 
        } 
      </div>
      }
    </FilterWrapper>
  )
}