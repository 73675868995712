import React from 'react'
import { analyticsApi, pimApi } from 'shared/api'

import { Charts } from '../../ui/Charts'
import { StyledLoader } from '../wb/wbPriceHistory'
import { useChartContext } from '../../../../../../lib/useChartContext'

export const OzonPriceHistory = () => {
  const { value: context } = useChartContext()
  const { searchObj } = context

  const { data: priceQuery } =
    pimApi.products.ozon.useFetchPriceHistoryQuery({
      productId: searchObj.productId!,
      fromDate: searchObj.startValue,
      toDate: searchObj.endValue,
    })

  const { data: ordersQuery } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom: searchObj.startValue,
        dateTo: searchObj.endValue,
        marketplaceId: searchObj.marketplaceId,
        units: searchObj.units,
        searchString: searchObj.searchString?.toString(),
        companyId: searchObj.companyId,
        productId: searchObj.productId,
        companymarketplaceId: searchObj.companymarketplaceId,
        goodsBrandId: searchObj.goodsBrandId,
        category: searchObj.category,
        syncStatus: searchObj?.syncStatus?.[0],
        stocksStatus: searchObj?.stocksStatus?.[0],
        autoOrdering: searchObj?.autoOrdering?.[0],
        contragentId: searchObj.contragentId,
        abcClass: searchObj.abcClass,
        xyzClass: searchObj.xyzClass
      })

  const priceData = priceQuery?.history.map(el => ({
    date: el.changeDate.split('T')[0],
    price: el.price
  }))

  const chartData = ordersQuery?.data.data.map(el => {
    if (priceData?.filter(elem => elem.date === el.orderDate).pop()?.price) {
      return ({
        orders: el.items,
        price: priceData?.filter(elem => elem.date === el.orderDate).pop()?.price,
        date: el.orderDate
      })
    }
    if ((priceData?.[0]?.date || '') > el.orderDate) {
      return ({
        orders: el.items,
        price: priceQuery?.startPrice,
        date: el.orderDate
      })
    }
    return ({
      orders: el.items,
      price: priceData?.filter(elem => elem.date < el.orderDate).pop()?.price,
      date: el.orderDate
    })
  })
  return (
    <>
      {(ordersQuery && chartData) ?
        <Charts
          ordersData={ordersQuery.data}
          chartData={chartData}/>
        :
        <div style={{ height: '641px' }}>
          <StyledLoader size={60}/>
        </div>
      }
    </>
  )
}