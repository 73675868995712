import { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { Field, Form, InputLabel, RadioField } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { history } from 'app/providers/with-router'
import { GoodsType } from 'shared/api/pim/goods/models'
import { pimApi } from 'shared/api'
import { EditCard } from 'shared/ui/layouts/EditCard'
import { validationSchema as validationSchemaPack } from 'features/goods/createPack/lib/validationSchema'
import { getGoodsEditPath } from 'shared/config/routes'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'

import { validationSchema } from './valdationSchema'

import { useCreateGoods } from '../model/hooks'
import {
  NewGoodsInfoValuesSubmit,
  GoodsInitialValues,
} from '../components/Steps/InfoStep/interface'
import { InfoStep } from '../components/Steps/InfoStep'
import { PaperTitle, BottomSection, BottomButtonWrapper } from '../components/common'


type Step = 'selectGoodsType' | 'createGoods' | 'createPack'

const StepToValidationSchema = (step) => {
  const stepToSchema = {
    selectGoodsType: {},
    createGoods: validationSchema,
    createPack: validationSchemaPack
  }
  return stepToSchema[step]
}

const initialValues: GoodsInitialValues = {
  name: '',
  goodsType: GoodsType.Common,
  suppliers: [],
}

const INITIAL_STEP: Step = 'selectGoodsType'

export const GoodsCreate = () => {
  const [currentStep, setCurrentStep] = useState<Step>(INITIAL_STEP)

  const { createGoods } = useCreateGoods()
  const { mutate: createPackMutaion } = pimApi.goods.useCreateGoodsPackMutation()

  const validate = useValidationSchema(StepToValidationSchema(currentStep))

  const handlePrev = useCallback(() => {
    setCurrentStep('selectGoodsType')
  }, [])

  const onSubmit = useCallback(
    (formValues) => {
      const goodsType = formValues?.goodsType
      if (goodsType===GoodsType.Pack) {
        setCurrentStep('createPack')
      } else {
        setCurrentStep('createGoods')
      }
      if (currentStep === 'createPack') {
        const packGoods = formValues?.packGoods[0]
        createPackMutaion(
          {
            goodsId: packGoods?.componentId,
            quantity: packGoods?.quantity,
            auxCode: formValues?.auxCode,
            code: formValues?.code
          },
          {
            onSuccess: (res) => {
              if (res.data?.id) history.push(getGoodsEditPath(res.data?.id))
            }
          }
        )
      } else if (currentStep === 'createGoods') {
        const { packGoods, ...rFormValues } = formValues
        createGoods(rFormValues).then(({ id }: pimApi.goods.Goods) => {
          if (id) history.push(getGoodsEditPath(id))
        })
      }
    },
    [currentStep]
  )

  return (
    <EditCard>
      <Form<NewGoodsInfoValuesSubmit, GoodsInitialValues>
        onSubmit={onSubmit}
        // @ts-ignore
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values, valid }) => (
          <form onSubmit={handleSubmit}>
            {currentStep === 'selectGoodsType' ? (
              <>
                <PaperTitle>Создание товара</PaperTitle>
                <InputLabel label="Тип товара" helpText="Выберите тип создаваемого товара" />
                <InputWrapper>
                  <Field size="small" name="goodsType" label="Товар" value="common" type="radio" component={RadioField}/>
                  <Field size="small" name="goodsType" label="Комплект" value="bundle" type="radio" component={RadioField}/>
                  <Field size="small" name="goodsType" label="Спайка" value="pack" type="radio" component={RadioField}/>
                  <Field size="small" name="goodsType" label="Ассортимент" value="assortment" type="radio" component={RadioField}/>
                </InputWrapper>
                <BottomSection>
                  <BottomButtonWrapper>
                    <Button variant="contained" color="primary" type="submit">ДАЛЕЕ</Button>
                  </BottomButtonWrapper>
                </BottomSection>
              </>
            ) : (
              <InfoStep valid={valid} values={values} onBackButtonClick={handlePrev} />
            )}
          </form>
        )}
      />
    </EditCard>
  )
}
