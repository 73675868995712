/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AllMethodOzonParams, ChangeOfferIdParams, OzonProduct } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<OzonProduct>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: AllMethodOzonParams | ChangeOfferIdParams
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/products/ozon/product/${productId}`,data).then(res => res.data)


export const usePatchProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

