import { Autocomplete, ButtonGroup } from '@mui/material'
import { DatePicker } from 'shared/ui/components'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { StyledChartsHeader } from 'features/analytics/chart/styled'
import { checkValue } from 'shared/lib/checkers'
import { analyticsGen } from 'shared/lib/generated'
import { dialogModel } from 'shared/ui/components/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import icon from 'assets/images/icons/excel.svg'
import TextField from '@mui/material/TextField'

import { FileExportModal } from '../../../modal/FileExportModal'
import { useChartContext } from '../../../../lib/useChartContext'
import { groupByDateOptions } from '../../../modal/options'
import { getDateByAggregationType } from '../../../../lib/aggregateData'

enum Interval { Week, Month, Year }
const unitsOptions = [
  { value: 'items', label: 'Штуки' },
  { value: 'price', label: 'Полочные рубли' },
  { value: 'incomingPrice', label: 'Закупочные рубли' }
]

export const Header = () => {
  const { value } = useChartContext()
  const { searchObj, handleChartParams, ordersData } = value

  const { mutate: downloadMutation, isLoading } = analyticsGen.analytics.exportToFileFilePost.useexportToFileFilePost()

  const handleExport = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <FileExportModal close={close} accept={accept} uploadingSettings={{
          groupby: searchObj.groupby || 'day',
          stats: 'orders'
        }}/>
      ),
      onAccept: ({ units, groupby, stats, view }) => {
        const {
          units: searchObjUnits,
          endValue,
          startValue,
          order,
          range,
          sort,
          chartType,
          timelineStart,
          timelineEnd,
          ...rest } = searchObj

        const startDate = () => {
          if (timelineStart && groupby === searchObj.groupby) {
            const { startDate: start } = getDateByAggregationType(timelineStart, groupby)
            return start
          }
          return startValue
        }

        const endDate = () => {
          if (timelineEnd && groupby === searchObj.groupby) {
            const { endDate: end } = getDateByAggregationType(timelineEnd, groupby)
            return end
          }
          return endValue
        }

        downloadMutation({
          query: {
            view,
            units,
            groupby,
            sort,
            dateFrom: startDate(),
            dateTo: endDate(),
            format: 'excel',
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined
          },
          data: {
            ...rest as any,
            stats: [stats],
            view,
            units,
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined,
            orderStatus: checkValue(searchObj?.orderStatus?.[0]),
            orderStatusInterval: checkValue(searchObj?.orderStatusInterval?.[0]),
          }
        }, {
          onSuccess: (response) => {
            const file = response.data
            const fileName =
              decodeURIComponent(response.headers['content-disposition']?.replace(
                'inline; filename=',
                ''
              ))
            downloadFile(file, fileName)
          },
          onError: () => {
            snackActions.error('Произошла ошибка при загрузке файла')
          }
        })
      }
    })
  }

  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        handleChartParams({
          timelineStart: subtractDays(new Date(), 7),
          timelineEnd: getPrevDay(),
          range: n,
        })
        return null
      }
      case 1: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 1),
          timelineEnd: getPrevDay(),
          range: n,
        })
        return null
      }
      case 2: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 12),
          timelineEnd: getPrevDay(),
          range: n
        })
        return null
      }
    }
    return true
  }

  const handleParamsChange = (paramsName, optionValue) => {
    if (paramsName === 'groupby') {
      handleChartParams({
        [paramsName]: optionValue.value,
        timelineStart: null,
        timelineEnd: null,
      })
    } else {
      handleChartParams({
        [paramsName]: optionValue.value,
      })
    }
  }

  return (
    <StyledChartsHeader>

      <span className="title-text">
        Заказы и остатки
      </span>

      <ButtonGroup size="large" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={searchObj.range === 0} onClick={() => rangeButtonClick(Interval.Week)}>Неделя</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 1} onClick={() => rangeButtonClick(Interval.Month)}>Месяц</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 2} onClick={() => rangeButtonClick(Interval.Year)}>Год</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 3} $isOtherButton={true}>Другое</StyledButtonGroup>
      </ButtonGroup>

      <div className="date-picker-container">
        <DatePicker
          name="dateFrom"
          label="Начало периода"
          views={['day']}
          inputHeight={36}
          showClear={false}
          disabled={!ordersData?.data.dateFrom}
          value={(searchObj.range === 2) ? ordersData?.data.dateFrom : searchObj.startValue}
          onChange={(e) => { if (e) handleChartParams({
            startValue: new Date(e).toISOString().split('T')[0],
            range: 3
          })}}
          onBlur={(e) => {
            if (e.target.value) {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateFrom) {
                handleChartParams({
                  startValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }}
          }}
        />
        <DatePicker
          name="dateTo"
          label="Конец периода"
          views={['day']}
          inputHeight={36}
          showClear={false}
          disabled={!ordersData?.data.dateTo}
          value={(searchObj.range === 2) ? ordersData?.data.dateTo : searchObj.endValue}
          onChange={(e) => {if (e) handleChartParams({
            endValue: new Date(e).toISOString().split('T')[0],
            range: 3
          })}}
          onBlur={(e) => {
            if (e.target.value) {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateTo) {
                handleChartParams({
                  endValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }}
          }}
        />
      </div>

      <div className="units-chart-container">
        <Autocomplete
          size="small"
          blurOnSelect={true}
          onChange={(_,option) => handleParamsChange('units',option)}
          disableClearable={true}
          value={unitsOptions.find(el => el.value === searchObj.units) || unitsOptions[0]}
          options={unitsOptions}
          renderInput={(params) =>
            <TextField {...params} label="Единица измерения"/>
          }/>

        <Autocomplete
          size="small"
          blurOnSelect={true}
          onChange={(_,option) => handleParamsChange('groupby',option)}
          disableClearable={true}
          value={groupByDateOptions.find(el => el.value === searchObj.groupby) || groupByDateOptions[0]}
          options={groupByDateOptions}
          renderInput={(params) =>
            <TextField {...params} label="Группировка по"/>
          }/>
      </div>


      <LoadingButton
        variant="contained"
        color="primary"
        loadingPosition="start"
        sx={{ marginLeft: 'auto' }}
        startIcon={<ExcelIcon src={icon} alt="excelImg"/>}
        onClick={handleExport}
        size="small"
        loading={isLoading}>
        ЭКСПОРТ
      </LoadingButton>
    </StyledChartsHeader>
  )
}