import { MarketplaceFormOzon } from '../ui/MarketplaceFormOzon/MarketplaceFormOzon'
import { MarketplaceFormWB } from '../ui/MarketplaceFormWB/MarketplaceFormWB'


export enum MarketplaceId {
  WB = 1,
  OZON = 2,
}

export const MarketplaceIdToMarketplaceForm = {
  [MarketplaceId.WB]: MarketplaceFormWB,
  [MarketplaceId.OZON]: MarketplaceFormOzon,
} as const
