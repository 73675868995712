import React, { memo } from 'react'
import { analyticsApi } from 'shared/api'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { useHistory } from 'react-router'
import { ChartsProvider } from 'features/analytics/chart/lib/useChartContext'
import { TableSelection as DXTableSelection } from '@devexpress/dx-react-grid-material-ui'
import { SelectionState } from '@devexpress/dx-react-grid'
import { Products } from 'pages/product/productList'
import { availableFilters } from 'pages/analytics/charts/ui/ChartsTabs'

import { Charts } from './ui/Charts'
import { Header } from './ui/Header'
import { columnsConfig } from './model/tableColumnsConfig'

import { ChartsDataTypes } from '../../../types/types'
import { StyledBlurContainer } from '../OrdersAndStocks/OrdersAndStocksPage'
import { ChartsPaper, StyledLoader, StyledProducts } from '../../../styled'
import { CustomSelectedComponent } from '../../table/ui'


const TimelineRange: Record<number, any> = {
  0: {
    length: {
      startValue: subtractDays(new Date(), 7),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 1),
    endValue:  getPrevDay()
  },
  1: {
    length: {
      startValue: subtractMonths(new Date(), 1),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 4),
    endValue:  getPrevDay()
  },
  2: {
    length: {
      startValue: subtractMonths(new Date(), 12),
      endValue: getPrevDay()
    },
    startValue: undefined,
    endValue:  getPrevDay()
  },
  3: {
    length: {}
  }
}
export const OrdersAndSalesPage = memo(() => {
  const { search } = useLocation()
  const history = useHistory()
  const searchObj: ChartsDataTypes = queryString.parse(search, { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' })

  const range = searchObj.range || 0
  const units = searchObj.units || 'items'
  const chartType = searchObj.chartType || 'chart'

  const startValue = range === 3 ?
    (searchObj?.startValue || TimelineRange[0].startValue) :
    TimelineRange[searchObj?.range?.toString() || 0].startValue
  const endValue = range === 3 ?
    (searchObj?.endValue || TimelineRange[0].endValue) :
    TimelineRange[searchObj?.range?.toString() || 0].endValue

  const timelineStart = searchObj.timelineStart || TimelineRange[searchObj?.range?.toString() || 0].length.startValue
  const timelineEnd = searchObj.timelineEnd || TimelineRange[searchObj?.range?.toString() || 0].length.endValue

  const handleChartParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace(`?${historyStr}`)
  }

  const { data: ordersData, isFetching: ordersFetching } =
      analyticsApi.useAnalyticsOrdersQuery(
        {
          dateFrom: startValue,
          dateTo: endValue,
          tags: searchObj.tags,
          marketplaceId: searchObj.marketplaceId,
          units,
          searchString: searchObj.searchString?.toString(),
          companyId: searchObj.companyId,
          productId: searchObj.productId,
          orderStatus: searchObj?.orderStatus?.[0],
          orderStatusInterval: searchObj?.orderStatusInterval?.[0] || 30,
          companymarketplaceId: searchObj.companymarketplaceId,
          goodsBrandId: searchObj.goodsBrandId,
          category: searchObj.category,
          syncStatus: searchObj?.syncStatus?.[0],
          stocksStatus: searchObj?.stocksStatus?.[0],
          autoOrdering: searchObj?.autoOrdering?.[0],
          contragentId: searchObj.contragentId,
          abcClass: searchObj.abcClass,
          xyzClass: searchObj.xyzClass
        })

  const { data: salesData, isFetching: stocksFetching,  } =
      analyticsApi.useAnalyticsSalesQuery(
        {
          dateFrom: startValue,
          dateTo: endValue,
          tags: searchObj.tags,
          marketplaceId: searchObj.marketplaceId,
          units,
          productId: searchObj.productId,
          searchString: searchObj.searchString?.toString(),
          companymarketplaceId: searchObj.companymarketplaceId,
          orderStatus: searchObj?.orderStatus?.[0],
          orderStatusInterval: searchObj?.orderStatusInterval?.[0] || 30,
          companyId: searchObj.companyId,
          goodsBrandId: searchObj.goodsBrandId,
          category: searchObj.category,
          syncStatus: searchObj?.syncStatus?.[0],
          stocksStatus: searchObj?.stocksStatus?.[0],
          autoOrdering: searchObj?.autoOrdering?.[0],
          contragentId: searchObj.contragentId,
          abcClass: searchObj.abcClass,
          xyzClass: searchObj.xyzClass
        })

  const onSelChange = (sel) => {
    const row = sel[sel.length - 1]
    handleChartParams({
      productId: row
    })
  }

  const SelectionCellComponent = (props: DXTableSelection.RowProps) => (
    <CustomSelectedComponent oneSelected={!!searchObj.productId} props={props}/>)

  const contextSearchObj = { ...searchObj, startValue, chartType, endValue, timelineEnd, timelineStart, range, units }
  return (
    <ChartsPaper>
      <ChartsProvider
        value={{
          searchObj: contextSearchObj,
          handleChartParams,
          ordersData,
          salesData }}>
        
        <Header/>
        
        {(ordersData && salesData) ?
          <StyledBlurContainer $isLoading={stocksFetching || ordersFetching}>
            <Charts isLoading={stocksFetching || ordersFetching}/>
          </StyledBlurContainer>
          :
          <div style={{ width: '100%', height: '590px' }}>
            <StyledLoader size={60}/>
          </div>
        }
        <StyledProducts>
          <Products
            showOrderStatus={true}
            filtersPresetsKey="chartsPresets"
            columnsSettingsId="salesProductsTable"
            columnsConfig={columnsConfig}
            availableFilters={availableFilters}>
            <SelectionState
              selection={[searchObj.productId || 0]}
              onSelectionChange={onSelChange}
            />
            <DXTableSelection
              rowComponent={SelectionCellComponent}
              highlightRow={true} showSelectionColumn={false} selectByRowClick={true}/>
          </Products>
        </StyledProducts>
      </ChartsProvider>
    </ChartsPaper>
  )
}
)