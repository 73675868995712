import React, { memo } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { useField, UseFieldConfig } from 'react-final-form'
import { getIsExistOnServer } from 'shared/lib/checkers/isExistOnServer'

import { GoodsInComponent } from './GoodsInComponent'
import { GoodsComponentSearch } from './GoodsComponentSearch'

import { GlobalParametersSelect } from '../../../../../widgets'


type ComponentsField = Array<AppPimGoodsModelsGoodsGoodsComponent>

const COMPONENT_FIELD_CONFIG: UseFieldConfig<ComponentsField> = { defaultValue: [] }

export const GoodsSearchSection = () => {
  const appPimGoodsModelsGoodsGoodsComponentField = useField<ComponentsField>(
    'selectedGoods',
    COMPONENT_FIELD_CONFIG
  )

  const appPimGoodsModelsGoodsGoodsComponent = appPimGoodsModelsGoodsGoodsComponentField.input.value

  const idField = useField<number>('id')
  const id = idField.input.value
  const isGoodsExistOnServer = getIsExistOnServer({ id })

  const handleAddComponent = (newComponent: AppPimGoodsModelsGoodsGoodsComponent) => {
    appPimGoodsModelsGoodsGoodsComponentField.input.onChange([
      ...appPimGoodsModelsGoodsGoodsComponent,
      newComponent
    ])
  }

  if (isGoodsExistOnServer) {
    return (
      <GoodsInComponent
        withRemove={!isGoodsExistOnServer}
      />
    )
  }

  return (
    <>
      <GoodsComponentSearch
        goodsComponents={appPimGoodsModelsGoodsGoodsComponent}
        onAddComponent={handleAddComponent}
      />
      <GoodsInComponent
        withRemove={!isGoodsExistOnServer}
      />
      <GlobalParametersSelect/>
    </>
  )
}

export const MemoGoodsSearchSection = memo(GoodsSearchSection)
