import { useQueryClient } from 'react-query'
import arrayMutators from 'final-form-arrays'
import { Box } from '@mui/material'
import { history } from 'app/providers/with-router'
import { productModel } from 'entities/pim/product'
import { OzonProductContextProvider } from 'entities/pim/product/model/ozon/useOzonProductContext'
import { Form } from 'shared/ui/components/form'
import { pimApi } from 'shared/api'
import { Preloader } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { snackActions } from 'shared/lib/react/snackbar'
import { DeleteConfirmModal } from 'entities/pim/goodsComponents'
import { dialogModel } from 'shared/ui/components/dialog'
import { GOODS_LIST } from 'shared/config'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { AnyObject } from 'final-form'
import { SSEProvider } from 'react-hooks-sse'
import { ozonProductStreamSource } from 'shared/lib/EventSource/SourcesList'
import { pimGen } from 'shared/lib/generated'

import { ProductInfoTabs } from './ProductInfoTabs'
import { MemoProductCard } from './ProductCard'
import { SaveForm } from './SaveForm'

import { serializeEditSubmitFormValuesToEditOzonCard } from '../lib/serializeEditSubmitFormValuesToEditOzonCard'
import { validationSchemaAll } from '../lib/validationSchema'
import { useFormInitialValues } from '../model'
import { ErrorText } from '../../WB/ui/styled'

interface OzonProductEditProps {
  productId: number
  commonProduct: pimApi.products.common.Product
}

export const OzonProductEdit = ({
  productId,
  commonProduct,
}: OzonProductEditProps) => {
  const queryClient = useQueryClient()
  const goods = useGoodsContext()
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(productId, {
    enabled: !!productId,
  })
  const { mutate: editOzonProduct, isLoading: editOzonProductInProgress } = pimApi.products.ozon.useEditOzonProductMutation()
  const { mutate: deleteProduct, isLoading: isDeleteProductLoading } =
    pimApi.products.ozon.useDeleteProductMutation()
  const { mutate: syncOzon, isLoading: isSyncOzonLoading } =
    pimGen.ozonProduct.PatchProduct.usePatchProduct()

  const initialValues = useFormInitialValues(productId, {
    ozonProductQueryData: ozonProductQuery.data,
    ozonProductQueryLoading: ozonProductQuery.isLoading,
    editOzonProductInProgress,
    companyId: commonProduct?.companyId,
    brandId: {
      value: ozonProductQuery.data?.brandId,
      label: ozonProductQuery.data?.brandName,
    },
  })

  const updateProduct = (formValues) => {
    const editOzonCardPayload =
      serializeEditSubmitFormValuesToEditOzonCard(formValues)

    editOzonProduct({
      productId,
      editedOzonCard: editOzonCardPayload
    }, {
      onSuccess( data ) {
        snackActions.info('Сохранено!')
        queryClient.setQueryData(
          pimApi.products.ozon.getOzonProductQueryKey(productId),
          data
        )
      },
    })
  }

  const handleSave = (formValues: productModel.ozonModel.EditFormValues) => {
    if (!ozonProductQuery.data?.inArchive) updateProduct(formValues)
  }
  
  const handleSync = (formValues: productModel.ozonModel.EditSubmitFormValues) => {
    const editOzonCardPayload = serializeEditSubmitFormValuesToEditOzonCard(formValues)
    syncOzon({ productId: editOzonCardPayload.id, data: { method: 'sync' } }, {
      onSuccess(response) {
        queryClient.setQueryData(
          pimApi.products.ozon.getOzonProductQueryKey(productId),
          response
        )
      },
    })
  }

  const handleFormSubmit = (formValues: productModel.ozonModel.EditSubmitFormValues) => {
    const { action, ...rFormValues } = formValues

      
    handleSave(rFormValues)
    if (action === 'sync') {
      handleSync(formValues)
    }
  }
  
  const getValidationSchema = () => validationSchemaAll


  const validate = useValidationSchema(getValidationSchema())

  if (!ozonProductQuery.data) return <Preloader />
  
  const handleDelete = () => {
    dialogModel.openDialog({
      component: DeleteConfirmModal,
      onAccept: () => {
        deleteProduct(
          {
            productId,
            id: ozonProductQuery.data.id,
            versionNo: ozonProductQuery.data.versionNo,
          },
          {
            onSuccess: () => {
              history.push(GOODS_LIST)
              snackActions.info('Продукт успешно удален!')
            },
          }
        )
      },
    })
  }

  const getValidationErrorText = (errors: AnyObject|undefined): string => {
    if (errors !== undefined) {
      let error_text = Object.values(errors)[0]
      if (typeof error_text === 'string') {
        return error_text
      }
      [error_text] = Object.values(error_text)
      if (typeof error_text === 'string') {
        return error_text
      }
    }
    return 'Ошибки в обязательных полях'
  }

  if (ozonProductQuery.isLoading) return null
  return (
    <SSEProvider key={productId} source={() => ozonProductStreamSource({ id: productId })}>
      <OzonProductContextProvider ozonProductQuery={ozonProductQuery}>
        <Form<
        productModel.ozonModel.EditSubmitFormValues,
        productModel.ozonModel.EditInitialFormValues
      >
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          subscription={{ initialValues: true, hasValidationErrors: true, submitFailed: true, errors: true }}
          validate={validate}
          render={({
            handleSubmit, 
            submitFailed,
            hasValidationErrors, 
            errors
          }) => (
            <form onSubmit={handleSubmit} style={{ maxWidth: 'inherit' }}>
              <MemoProductCard
                commonProductData={commonProduct}
                goodsData={goods}
              />
              {hasValidationErrors && submitFailed && (
                <Box mt={2} display="flex" flexWrap="wrap">
                  <Box display="flex" flexBasis="100%">
                    <ErrorText>{getValidationErrorText(errors)}</ErrorText>
                  </Box>
                </Box>
              )}
              <Box mt={3}>
                <ProductInfoTabs commonProduct={commonProduct}/>
              </Box>
              <SaveForm
                isSyncOzonLoading={isSyncOzonLoading}
                isDeleteProductLoading={isDeleteProductLoading}
                handleDelete={handleDelete}
                editOzonProductInProgress={editOzonProductInProgress}
                ozonProductQuery={ozonProductQuery}
              />
            </form>
          )}
        />
      </OzonProductContextProvider>
    </SSEProvider>
  )
}
