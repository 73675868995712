import React, { useMemo, useState } from 'react'
import { Autocomplete, Box, Divider, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DownloadIcon from '@mui/icons-material/Download'
import { pimApi } from 'shared/api'
import { downloadFile } from 'shared/lib/utils'
import { InputLabel } from 'shared/ui/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { Accordion } from 'shared/ui/components/Accordion'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkOperationsResultModal } from 'widgets/product'
import { useQuery } from '@apollo/client'
import TextField from '@mui/material/TextField'
import { history } from 'app/providers/with-router'
import { Option } from 'shared/ui/components/interface'
import { GET_FILTERS_QUERY } from 'shared/api/products/getBulkOperationsFilters'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import Chip from '@mui/material/Chip'

export const FilterNames: Record<string, string> = {
  'marketplaceId': 'Маркетплейс',
  'companyId': 'Компания',
  'contragentId': 'Поставщик',
  'companymarketplaceId': 'Кабинет',
}

interface OptionItem {
  name?: string
  id: number
}

export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
})

const DEFAULT_FILE_NAME = 'template.xlsx'
const curDate = new Date()
const defaultDateValue = new Date(
  new Date(curDate.setHours(24, 0, 0, 0)).getTime() -
  curDate.getTimezoneOffset() * 60000
).toISOString().substring(0, 16)

interface QueryParamsType {
  marketplaceId?: Array<any>
  companyId?: Array<any>
  companymarketplaceId?: Array<any>
  contragentId?: Array<any>
  pricechangereasonId?: number
  commentsField?: string
  changeDataField?: string
  revertDateField?: string
}

export const BulkPriceChangeForm = () => {
  const [ queryParams, setQueryParams ] = useState<QueryParamsType>({})
  const [graphQueryParams, setGraphQueryParams] = useState<QueryParamsType>({})
  
  const { data: filtersData, previousData } = useQuery(GET_FILTERS_QUERY, {
    variables: {
      syncStatusId: [3],
      companyId: graphQueryParams.companyId?.map(el => el.value),
      marketplaceId: graphQueryParams.marketplaceId?.map(el => el.value),
      companymarketplaceId: graphQueryParams.companymarketplaceId?.map(el => el.value),
      contragentId: graphQueryParams.contragentId?.map(el => el.value)
    }, context: { apiName: 'products' } })

  const filters = filtersData?.products?.filters || previousData?.products?.filters

  const priceChangeReasonsQuery = pimApi.dictionaries.usePriceChangeReasonsQuery()

  const priceChangeReasonOptionList = useMemo(
    () => priceChangeReasonsQuery.data?.map(getOptions) || [],
    [priceChangeReasonsQuery.data]
  )

  const { mutate: downloadWBTemplate, isLoading: isLoadingWBTemplate } =
    pimApi.products.wb.useDownloadWBChangePriceTemplateFile()
  const { mutate: uploadWBTemplate, isLoading: isUploadingWBTemplate } =
    pimApi.products.wb.useUploadWBChangePriceFile()
  const { mutate: downloadOzonTemplate, isLoading: isLoadingOzonTemplate } =
    pimApi.products.ozon.useDownloadOzonChangePriceTemplateFile()
  const { mutate: uploadOzonTemplate, isLoading: isUploadingOzonTemplate } =
    pimApi.products.ozon.useUploadOzonChangePriceFile()
  const { mutate: downloadSimpleTemplate, isLoading: isLoadingSimpleTemplate } =
    pimApi.products.simple.useDownloadSimpleChangePriceTemplateFile()
  const { mutate: uploadSimpleTemplate, isLoading: isUploadingSimpleTemplate } =
    pimApi.products.simple.useUploadSimpleChangePriceFile()

  const isLoadingTemplate = isLoadingWBTemplate || isLoadingOzonTemplate || isLoadingSimpleTemplate
  const isUploadingTemplate = isUploadingWBTemplate || isUploadingOzonTemplate || isUploadingSimpleTemplate

  const downloadTemplate = () => {
    let downloadTemplateFunction = downloadSimpleTemplate
    if (queryParams.marketplaceId?.[0]?.value === 1) {
      downloadTemplateFunction = downloadWBTemplate
    }
    if (queryParams.marketplaceId?.[0]?.value === 2) {
      downloadTemplateFunction = downloadOzonTemplate
    }
    downloadTemplateFunction({ 
      companymarketplaceId: queryParams.companymarketplaceId?.map(el => el.value) || null,
      contragentId: queryParams.contragentId?.map(el => el.value) || null,
      companyId: queryParams.companyId?.map(el => el.value) || null
    },
    {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ) || DEFAULT_FILE_NAME

        downloadFile(file, decodeURIComponent(fileName.replace(/%2C/g, ',')))
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }
  const uploadTemplate = ({ file }: { file: File }) => {
    const comments = queryParams.commentsField
    const changeDate = queryParams.changeDataField
    const revertDate = queryParams.revertDateField
    let uploadTemplateFunction = uploadSimpleTemplate
    if (queryParams.marketplaceId?.[0]?.value === 1) {
      uploadTemplateFunction = uploadWBTemplate
    }
    if (queryParams.marketplaceId?.[0]?.value === 2) {
      uploadTemplateFunction = uploadOzonTemplate
    }
    uploadTemplateFunction(
      {
        file,
        pricechangereasonId: queryParams.pricechangereasonId || 0,
        comments,
        ...(changeDate ? { changeDate } : {}),
        ...(revertDate ? { revertDate } : {}),
      },
      {
        onSuccess: (data) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  return (
    <>
      <Box mb={3} mt="28px">
        <Grid container={true} spacing={3}>
          <Grid container={true} spacing={2} pl={3} pt={3}>
            {filters &&
            <>
              {Object.keys(filters).map((el, index) => (
                <Grid item={true} xs={4.9}>
                  <InputLabel required={el === 'marketplaceId'} label={FilterNames[el]}/>
                  <InputWrapper>
                    <Autocomplete
                    /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      options={filters[el]}
                      noOptionsText="Нет фильтров"
                      value={queryParams[el] || []}
                      clearOnBlur={false}
                      disableCloseOnSelect={true}
                      multiple={true}
                      fullWidth={true}
                      disableClearable={true}
                      size="small"
                      isOptionEqualToValue={isOptionEqual}
                      onBlur={() => setGraphQueryParams(queryParams)}
                      onChange={(event, value, reason, details) => {
                        if (value) {
                          setQueryParams(
                            prev =>({ ...prev, [el]: el !== 'marketplaceId' ? value : [details!.option] }))
                        }
                        if (value.length === 0) {
                          setQueryParams(prev =>({ ...prev, [el]: [] }))
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => 
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        tagValue.map((option: any, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            label={option.label}
                            size="small"
                            sx={{ backgroundColor: '#e9f4fe' }}
                            onDelete={() => {
                              if (queryParams[el].length > 1) {
                                setQueryParams(prev => ({ ...prev, [el]: prev[el]?.filter(elem => elem.value !== option.value) }))
                                setGraphQueryParams(prev => ({ ...prev, [el]: prev[el]?.filter(elem => elem.value !== option.value) }))
                              } else {
                                setQueryParams(prev => ({ ...prev, [el]: undefined }))
                                setGraphQueryParams(prev => ({ ...prev, [el]: undefined }))
                              }
                            }}/>
                        ))
                      }
                      renderOption={(props: object, option: any) => (
                        <div {...props}>
                          {option.label}
                          {option.count &&
                          <div style={{ paddingLeft: '16px', marginLeft: 'auto', marginRight: '0' }}>
                            <span style={{ fontSize: '16px', color: '#00000099' }}>{option.count}</span>
                          </div>
                          }
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={FilterNames[el]}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </InputWrapper>
                </Grid>
              ))}
            </>
            }
          </Grid>
        </Grid>
      </Box>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Divider />
          <Box display="flex" justifyContent="end" mt="18px" mb={3}>
            <LoadingButton
              color="primary"
              loading={isLoadingTemplate}
              disabled={!queryParams.marketplaceId}
              startIcon={<DownloadIcon />}
              onClick={downloadTemplate}
            >
              Скачать шаблон
            </LoadingButton>
          </Box>
          <InputLabel label="Выберите причину изменения цен" />
          <InputWrapper>
            <Autocomplete
              value={priceChangeReasonOptionList.find(el => el.value === queryParams.pricechangereasonId)}
              placeholder="Причина"
              fullWidth={true}
              size="small"
              options={priceChangeReasonOptionList}
              onChange={(event, value) => {
                if (value) setQueryParams(prev => ({ ...prev, pricechangereasonId: value.value })) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Причина"
                  InputProps={{ ...params.InputProps, endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  }}
                />
              )}
            />
          </InputWrapper>
          <Accordion
            title="Добавить комментарий"
            sx={{ marginTop: '16px' }}
            onClose={() => {}}
            single={true}
            defaultExpanded={!!queryParams.contragentId}
          >
            <TextField
              name="comments"
              size="small"
              label="Комментарий"
              onChange={(event) => {setQueryParams(prev => ({ ...prev, commentsField: event.target.value }))}}
              multiline={true}
              fullWidth={true}
              minRows={1}
              maxRows={10}
            />
            <Box mt="4px">
              <span
                style={{
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '20px',
                }}>
                Символов: {queryParams?.commentsField?.length ?? 0}
              </span>
            </Box>
          </Accordion>
        </Grid>
        <Grid item={true} xs={12}>
          <Accordion
            title="Запланировать время изменения"
            onOpen={() => setQueryParams(prev => ({ ...prev, changeDataField: defaultDateValue }))}
            onClose={() => setQueryParams(prev => ({ ...prev, changeDataField: undefined }))}
          >
            <Grid item={true} xs={3} pb={0}>
              <TextField
                value={queryParams.changeDataField}
                name="changeData"
                size="small"
                onChange={event => setQueryParams(prev => ({ ...prev, changeDataField: event.target.value }))}
                label="Дата время"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Accordion>
          <Accordion
            title="Вернуться к исходной цене"
            onOpen={() => setQueryParams(prev => ({ ...prev, revertDateField: defaultDateValue }))}
            onClose={() => setQueryParams(prev => ({ ...prev, revertDateField: undefined }))}
          >
            <Grid item={true} xs={3} pb={0}>
              <TextField
                value={queryParams.revertDateField}
                name="revertDate"
                size="small"
                onChange={event => setQueryParams(prev => ({ ...prev, revertDateField: event.target.value }))}
                label="Дата время"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end" mt={3} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <Divider />
      <Grid item={true} xs={12} p={0} mb={3}>
        <BulkFileActionsFooter
          upload={uploadTemplate}
          isUploading={isUploadingTemplate}
          onGoBack={() => { history.goBack() }}
          disabled={!queryParams.pricechangereasonId || !queryParams.marketplaceId}
        />
      </Grid>
    </>
  )
}
