import { useField } from 'react-final-form'
import { Box, Grid, TextField } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { productModel } from 'entities/pim/product'
import { CheckboxField } from 'shared/ui/components'
import { Field, InputField } from 'shared/ui/components/form'
import { Stocks } from 'features/product/stocks'
import { pimApi, wbproxyApi } from 'shared/api'
import { StocksAndOrders } from 'features/product/stockAndOrders'
import { AbcClassCard } from 'widgets/product/Common/abcClass'
import React, { useMemo, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { ProductBarcodes } from 'widgets/ProductBarcodes'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { snackActions } from 'shared/lib/react/snackbar'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { ProductsProfitability } from 'widgets/product/Common/Profitability'
import { getDiscountPrice } from 'entities/pim/price/lib'
import { ContentRating } from 'widgets/product/WB/ContentRating'

import {
  GridWithArrow,
  InputArrow,
  SideBlock,
  VarBlock,
  Sizes, BarcodeBlock
} from '../../styled'
import { isNil, isNotNil } from '../../../../../../../../../shared/lib/checkers'

interface VariationsProps {
  name: string
  productId: number
}

export const VariationsForm = ({
  name,
  productId,
}: VariationsProps) => {
  const sizeSupportField = useField<boolean>('sizeSupport')
  const sizeAttributeIdField = useField<boolean>('sizeAttributeId')
  const sizeAttributeField = useField('sizeAttribute')
  const sizeValueField = useField<productModel.wbModel.SizeValue>('sizeValue')
  const isArchivedField = useField(`${name}.inArchive`)
  const price = useField('price').input.value || null
  const discount = useField('discount').input.value || null
  const priceWithDiscount = getDiscountPrice(price, discount)
  const isArchived = isArchivedField.input.value
  const { wbProductQuery } = useWBProductContext()
  const abcClass = useMemo(() => {
    const variationSelected = wbProductQuery.data?.variations?.find(
      (variation) => variation?.productId === productId
    )
    return variationSelected?.abcClass
  },[wbProductQuery, productId])
  const [ barcodes, variation] = useMemo( () => {
    const variationSelected = wbProductQuery.data?.variations?.find((v) => v?.productId === productId)
    return [ variationSelected?.barcodes, variationSelected ]
  },[wbProductQuery, productId])

  const queryClient = useQueryClient()
  // Get content rating
  // const { data: contentRatingData } = pimApi.products.wb.useFetchContentRatingQuery(productId)

  const { commonProductQuery } = useCommonProductContext()
  const { mutate: getBarcodeFromWB, isLoading: gettingBarcodeFromWB } = wbproxyApi.useFetchBarcodeMutation()
  const { mutate: createProductBarcode, isLoading: createProductBarcodeInProgress } = pimApi.products.wb.useCreateProductBarcodeMutation()
  const { mutate: editProductBarcode, isLoading: editProductBarcodeInProgress } = pimApi.products.wb.useEditProductBarcodeMutation()
  const { mutate: deleteProductBarcode, isLoading: deleteProductBarcodeInProgress } = pimApi.products.wb.useDeleteProductBarcodeMutation()
  const { mutate: updateVariation, isLoading: variationUpdateInProgress } = pimApi.products.wb.useUpdateVariationMutation()

  const getBarcode = useCallback((cb) => {
    if (commonProductQuery?.data?.companymarketplaceId !== undefined) {
      getBarcodeFromWB({
        companymarketplaceId: commonProductQuery.data.companymarketplaceId,
      }, {
        onSuccess: (data) => {
          cb(data?.data?.barcode)
        }
      }
      )
    }
  }, [commonProductQuery])

  const createNewBarcode = useCallback((barcode: pimApi.products.NewBarcode, onCreate: () => void) => {
    if (isNotNil(variation)) {
      createProductBarcode(
        { productId, variationId: variation.id, barcode },
        {
          onSuccess: (newBarcode) => {
            queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(
              pimApi.products.wb.getWBProductQueryKey(productId),
              (updater) => {
                const newObj = JSON.parse(JSON.stringify(updater))
                return {
                  ...newObj,
                  variations: newObj.variations.map(v => {
                    if (v.id === variation.id) {
                      return {
                        ...v,
                        barcodes: [...v.barcodes, newBarcode],
                        ...(isNil(v.mainBarcodeId) && { mainBarcodeId: newBarcode.id })
                      }
                    }
                    return v
                  })
                }
              }
            )
            snackActions.info('Штрих-код добавлен на продукт!')
            onCreate()
          }
        })
    }
  }, [productId, variation])

  const editBarcode = useCallback((barcode: pimApi.products.EditBarcode) => {
    if (variation!==undefined) {
      editProductBarcode(
        { productId, variationId: variation.id, barcode },
        { onSuccess: (editedBarcode) => {
          queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(
            pimApi.products.wb.getWBProductQueryKey(productId),
            (curr) => {
              const newObj = { ...curr }
              newObj.variations = newObj?.variations?.map(v => {
                if (v.id === variation.id) {
                  const newV = { ...v }
                  newV.barcodes = newV.barcodes?.map(b => {
                    if (b.id === editedBarcode.id) {
                      return editedBarcode
                    }
                    return b
                  })
                  return newV
                }
                return v
              })
              return newObj as pimApi.products.wb.WBNomenclature
            }
          )
          snackActions.info('Штрих-код изменён успешно!')
        }
        })
    }
  }, [productId, variation])

  const deleteBarcode = useCallback((barcode: DeletingObject) => {
    if (variation!==undefined) {
      deleteProductBarcode(
        { productId, variationId: variation.id, barcode },
        { onSuccess: () => {
          queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(
            pimApi.products.wb.getWBProductQueryKey(productId),
            (curr) => {
              const newObj = { ...curr }
              newObj.variations = newObj?.variations?.map(v => {
                if (v.id === variation.id) {
                  const newV = { ...v }
                  newV.barcodes = newV.barcodes?.filter(b => b.id !== barcode.id)
                  return newV
                }
                return v
              })
              return newObj as pimApi.products.wb.WBNomenclature
            }
          )
          snackActions.info('Штрих-код удалён!')
        }
        })
    }
  }, [productId, variation])

  const makeMainBarcode = useCallback((barcodeId: UniqueId) => {
    if (variation!==undefined) {
      updateVariation({
        productId,
        variationId: variation.id,
        editedVariation: {
          id: variation.id,
          versionNo: variation.versionNo,
          mainBarcodeId: barcodeId,
        }
      },
      {
        onSuccess: (editedVariation) => {
          queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(
            pimApi.products.wb.getWBProductQueryKey(productId),
            (curr) => {
              const newObj = { ...curr }
              newObj.variations = newObj?.variations?.map(v => {
                if (v.id === editedVariation.id) {
                  return editedVariation
                }
                return v
              })
              return newObj as pimApi.products.wb.WBNomenclature
            }
          )
          snackActions.info('Штрих-код сделан основным!')
        }
      })
    }
  }, [productId, variation])


  return (
    <Box>
      {sizeAttributeIdField.input.value && (
        <GridWithArrow item={true} xs={3}>
          <>
            <TextField
              label={sizeAttributeField.input.value}
              variant="outlined"
              size="small"
              fullWidth={true}
              value={sizeValueField.input.value}
              disabled={true}
            />

            <InputArrow>
              <ArrowForward />
            </InputArrow>
          </>
        </GridWithArrow>
      )}
      <VarBlock>
        <SideBlock $isSeparate={!!sizeSupportField.input.value}>
          <Box>
            <Field
              name={`${name}.autoOrdering`}
              label="Автозаказ"
              type="checkbox"
              size="small"
              component={CheckboxField}
              disabled={isArchived}
            />
            <Field
              name={`${name}.syncEnabled`}
              label="Синхр. с мп"
              type="checkbox"
              size="small"
              component={CheckboxField}
              disabled={isArchived}
            />
          </Box>

          {sizeSupportField.input.value && (
            <Sizes>
              <Field
                name={`${name}.size`}
                label="Размер WB"
                component={InputField}
                disabled={isArchived}
              />
              <Field
                name={`${name}.ruSize`}
                label="Рос. размер"
                component={InputField}
                disabled={isArchived}
              />
            </Sizes>
          )}
        </SideBlock>
        <BarcodeBlock $isSeparate={!!sizeSupportField.input.value} >
          <ProductBarcodes
            barcodes={barcodes}
            editingDisabled={
              isArchived || createProductBarcodeInProgress || editProductBarcodeInProgress ||
              deleteProductBarcodeInProgress || variationUpdateInProgress
            }
            mainBarcodeId={variation?.mainBarcodeId}
            marketPlaceAdvicedBarcode={
              {
                labelText: 'Сгенерировать из WB',
                isLoading: gettingBarcodeFromWB,
                getBarcode
              }
            }
            createNewBarcode={createNewBarcode}
            editBarcode={editBarcode}
            deleteBarcode={deleteBarcode}
            makeMainBarcode={makeMainBarcode}
          />
        </BarcodeBlock>
      </VarBlock>

      <Grid item={true} xs={12} display="flex" gap={2}>
        <Box display="flex" flexDirection="column" gap="16px">
          <AbcClassCard abc={abcClass} />
          <ContentRating nmid={wbProductQuery?.data?.nmid} productId={productId}/>
        </Box>
        <Stocks productId={productId} />
        <StocksAndOrders productId={productId} />
        <ProductsProfitability productId={productId} price={priceWithDiscount}/>
      </Grid> 
    </Box>
  )
}
