import { Autocomplete, ButtonGroup } from '@mui/material'
import { DatePicker } from 'shared/ui/components'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { dialogModel } from 'shared/ui/components/dialog'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { StyledChartsHeader } from 'features/analytics/chart/styled'
import LoadingButton from '@mui/lab/LoadingButton'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import icon from 'assets/images/icons/excel.svg'
import { analyticsGen } from 'shared/lib/generated'
import { checkValue } from 'shared/lib/checkers'
import TextField from '@mui/material/TextField'
import { useChartContext } from 'features/analytics/chart/lib/useChartContext'

import { FileExportModal } from '../../../modal/FileExportModal'
import { groupByDateOptions } from '../../../modal/options'
import { getDateByAggregationType } from '../../../../lib/aggregateData'

enum Interval { Month, HalfYear, Year}
const unitsOptions = [
  { value: 'items', label: 'Штуки' },
  { value: 'price', label: 'Полочные рубли' },
  { value: 'incomingPrice', label: 'Закупочные рубли' }
]

export const Header = () => {
  const { value: context } = useChartContext()
  const { searchObj, handleChartParams, ordersData } = context

  const { mutate: downloadMutation, isLoading } = analyticsGen.analytics.exportToFileFilePost.useexportToFileFilePost()

  const handleExport = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <FileExportModal close={close} accept={accept} uploadingSettings={{
          groupby: searchObj.groupby || 'day',
          stats: 'orders'
        }}/>
      ),
      onAccept: ({ units, groupby, stats, view }) => {
        const {
          units: searchObjUnits,
          endValue,
          startValue,
          timelineStart,
          timelineEnd,
          order,
          range,
          sort,
          chartType,
          ...rest } = searchObj

        const startDate = () => {
          if (timelineStart && searchObj.groupby === groupby) {
            const { startDate: start } = getDateByAggregationType(timelineStart, groupby)
            return start
          }
          return startValue
        }

        const endDate = () => {
          if (timelineEnd && searchObj.groupby === groupby) {
            const { endDate: end } = getDateByAggregationType(timelineEnd, groupby)
            return end
          }
          return endValue
        }

        downloadMutation({
          query: {
            view,
            units,
            groupby,
            sort,
            dateFrom: startDate(),
            dateTo: endDate(),
            format: 'excel',
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined
          },
          data: {
            ...rest as any,
            stats: [stats],
            view,
            units,
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined,
            orderStatus: checkValue(searchObj?.orderStatus?.[0]),
            orderStatusInterval: checkValue(searchObj?.orderStatusInterval?.[0]),
          }
        }, {
          onSuccess: (response) => {
            const file = response.data
            const fileName =
                decodeURIComponent(response.headers['content-disposition']?.replace(
                  'inline; filename=',
                  ''
                ))
            downloadFile(file, fileName)
          },
          onError: () => {
            snackActions.error('Произошла ошибка при загрузке файла')
          }
        })
      }
    })
  }

  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        handleChartParams({
          timelineStart: subtractDays(new Date(), 7),
          timelineEnd: getPrevDay(),
          range: n,
        })
        return null
      }
      case 1: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 1),
          timelineEnd: getPrevDay(),
          range: n,
        })
        return null
      }
      case 2: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 12),
          timelineEnd: getPrevDay(),
          range: n
        })
        return null
      }
    }
    return true
  }

  const handleParamsChange = (paramsName, optionValue) => {
    if (paramsName === 'groupby') {
      handleChartParams({
        [paramsName]: optionValue.value,
        timelineStart: null,
        timelineEnd: null,
      })
    } else {
      handleChartParams({
        [paramsName]: optionValue.value,
      })
    }
  }

  const chartTypeClick = (value) => {
    handleChartParams({
      chartType: value
    })
  }

  return (
    <StyledChartsHeader>

      <span className="title-text">
        Динамика продаж
      </span>

      <ButtonGroup size="large" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={ searchObj.range === 0 } onClick={ () => rangeButtonClick(Interval.Month) }>Неделя</StyledButtonGroup>
        <StyledButtonGroup $active={ searchObj.range === 1 } onClick={ () => rangeButtonClick(Interval.HalfYear) }>Месяц</StyledButtonGroup>
        <StyledButtonGroup $active={ searchObj.range === 2 } onClick={ () => rangeButtonClick(Interval.Year) }>Год</StyledButtonGroup>
        <StyledButtonGroup $active={ searchObj.range === 3 } $isOtherButton={ true }>Другое</StyledButtonGroup>
      </ButtonGroup>


      <div className="date-picker-container">
        <DatePicker
          name="dateFrom"
          label="Начало периода"
          inputHeight={ 36 }
          views={ ['day'] }
          showClear={ false }
          disabled={!ordersData?.data.dateFrom}
          value={ (searchObj.range === 2 && ordersData?.data.dateFrom) ? ordersData?.data.dateFrom : searchObj.startValue }
          onChange={ (e) => {
            if (e) handleChartParams({
              startValue: new Date(e).toISOString().split('T')[0],
              range: 3
            })
          } }
          onBlur={ (e) => {
            const [day, month, year] = e.target.value.split('.')
            if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateFrom) {
              handleChartParams({
                startValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                range: 3
              })
            }
          } }
        />
        <DatePicker
          name="dateTo"
          label="Конец периода"
          inputHeight={ 36 }
          views={ ['day'] }
          showClear={ false }
          value={ searchObj.endValue }
          disabled={!ordersData?.data.dateTo}
          onChange={ (e) => {
            if (e) handleChartParams({
              endValue: new Date(e).toISOString().split('T')[0],
              range: 3
            })
          } }
          onBlur={ (e) => {
            const [day, month, year] = e.target.value.split('.')
            if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateTo) {
              handleChartParams({
                endValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                range: 3
              })
            }
          } }
        />
      </div>

      <div className="units-chart-container">
        <Autocomplete
          size="small"
          blurOnSelect={true}
          onChange={(_,option) => handleParamsChange('units',option)}
          disableClearable={true}
          value={unitsOptions.find(el => el.value === searchObj.units) || unitsOptions[0]}
          options={unitsOptions}
          renderInput={(params) =>
            <TextField {...params} label="Единица измерения"/>
          }/>

        <Autocomplete
          size="small"
          blurOnSelect={true}
          onChange={(_,option) => handleParamsChange('groupby',option)}
          disableClearable={true}
          value={groupByDateOptions.find(el => el.value === searchObj.groupby) || groupByDateOptions[0]}
          options={groupByDateOptions}
          renderInput={(params) =>
            <TextField {...params} label="Группировка по"/>
          }/>

        <ButtonGroup size="large" variant="text" aria-label="text button group">
          <StyledButtonGroup
            $active={ searchObj.chartType === 'chart' }
            onClick={ () => chartTypeClick('chart') }>
            График
          </StyledButtonGroup>
          <StyledButtonGroup
            $active={ searchObj.chartType === 'diagram' }
            onClick={ () => chartTypeClick('diagram') }>
            Диаграмма
          </StyledButtonGroup>
        </ButtonGroup>
      </div>

      <LoadingButton
        variant="contained"
        color="primary"
        loadingPosition="start"
        sx={{ marginLeft: 'auto' }}
        startIcon={<ExcelIcon src={icon} alt="excelImg"/>}
        onClick={handleExport}
        size="small"
        loading={isLoading}>
        ЭКСПОРТ
      </LoadingButton>
    </StyledChartsHeader>
  )
}