import React from 'react'
import { useField } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  TextField,
  Tooltip,
} from '@mui/material'
import { InputField, SelectField } from 'shared/ui/components'
import { Field } from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { Option } from 'shared/ui/components/interface'
import { pimApi } from 'shared/api'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { TextError } from 'pages/product/edit/main/ui/Ozon/ui/ProductInfoTabs/styled'
import { OzonBrandSelectField } from 'features/product/OZON/GeneralInformation/field/OzonBrandSelectField'
import { MemoECOMMarketplace } from 'pages/product/edit/main/ui/WB/ui/ProductInfoTabs/ui/ECOMWB'
import { DisplayedGoodsDataController } from 'pages/product/edit/main/model/DisplayedGoodsDataController'

import SizesForm from './SizesForm'


const description = {
  description:
    'Опишите товар. Требования к описанию: 1) Грамотное, без ошибок. 2) Соответствует названию и товару' +
    'на изображениях. 3) Раскрывает потребительские свойста товара. 4) Не содержит ссылок или отсылок к другому источнику,' +
    'например, буклету. Подробнее в Помощи https://seller-edu.ozon.ru/docs/work-with-goods/trebovaniya-k-kartochkam-tovarov/' +
    'text-o-tovare.html#как-использовать-в-описании-rich-контент.',
  vatRate:
    'Укажите ставку НДС. Если товар не облагается НДС или вы не платите НДС, укажите 0.',
  name:
    'Ознакомьтесь с Требованиями к названию https://seller-edu.ozon.ru/docs/nazvanie.html, чтобы указать правильное название товара' +
    ' и пройти модерацию.',
  category:
    'Укажите категорию товара — по ней вам будет начисляться комиссия. Категория товара в личном кабинете не равна категории, ' +
    'в которой товар будет отображаться на сайте Ozon. Чтобы верно выбрать категорию товара, воспользуйтесь калькулятором комиссий.',
  brand:
    'Укажите название бренда производителя. Если у товара нет бренда, укажите «No name / Нет бренда». Если бренд еще не представлен ' +
    'на Ozon, при выборе бренда нажмите Запросить добавление нового бренда, введите его название и нажмите Добавить или создайте заявку' +
    ' в службу поддержки.',
}


const VAT_RATE_OPTIONS: Array<Option<number>> = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 0.05,
    label: '5%',
  },
  {
    value: 0.07,
    label: '7%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.2,
    label: '20%',
  },
]

export function GeneralInformation() {
  const { id } = useParams<{ id: string }>()
  const goods = useGoodsContext()
  const { meta: vatRateMeta } = useField('vatRate')
  const vatRateError = vatRateMeta.error && vatRateMeta.touched
  const editPossible = useField('editPossible')
  const companymarketplaceId = useField<UniqueId | null>('companymarketplaceId').input.value
  const isDraft = !editPossible.input.value
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(
    parseInt(id, 10)
  )
  const isArchived = ozonProductQuery.data?.inArchive

  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()

  return (
    <>
      <MemoECOMMarketplace
        leftSideTooltip="Характеристики товара, к которому привязан продукт"
        changeDisplayedGoodsData={changeDisplayedGoodsData('generalInformation')}
        displayGoodsData={displayedGoodsData.generalInformation}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description={description.name}>
          <TextField
            label="Название"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={goods?.name ?? ''}
            disabled={true}
          />
          <Field
            name="name"
            label="Название*"
            component={InputField}
            disabled={isDraft || isArchived}
          />
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description={description.category}>
          <TextField
            label="Категория"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={goods?.category ?? ''}
            disabled={true}
          />
          <TextField
            label="Категория"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={`${ozonProductQuery?.data?.parentCategoryName}/${ozonProductQuery?.data?.categoryName}` ?? ''}
            disabled={true}
          />
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description={description.brand}>
          <TextField
            label="Бренд"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={goods?.brand ?? ''}
            disabled={true}
          />
          <Field
            name="brandId"
            label="Бренд*"
            errorAfterTouch={true}
            disabled={isDraft || isArchived}
            component={OzonBrandSelectField}
            companymarketplaceId={companymarketplaceId}
          />
        </InputsWrapper>

        <SizesForm showGoodsInfo={displayedGoodsData.generalInformation} goods={goods} disabled={isArchived} />

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation}>
          <TextField
            label="НДС"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={goods?.vatRate ? `${goods?.vatRate * 100}%` : ''}
            disabled={true}
          />
          <Box width="100%">
            <InputWrapper>
              <Field
                name="vatRate"
                label="НДС"
                component={SelectField}
                options={VAT_RATE_OPTIONS}
                errorAfterTouch={true}
                disabled={isDraft || isArchived}
              />
              {vatRateError && <TextError>Выберете ставку НДС</TextError>}
            </InputWrapper>
          </Box>
          <Tooltip title={description.vatRate} placement="right">
            <HelpWithMargin>
              <InfoOutlined />
            </HelpWithMargin>
          </Tooltip>
        </InputsWrapper>
      </Box>
    </>
  )
}
