import React, { useCallback, useState } from 'react'
import { useField } from 'react-final-form'
import { Box, Grid } from '@mui/material'
import { AxiosResponse } from 'axios'
import { OzonBrandSelectField } from 'features/product/OZON/GeneralInformation/field/OzonBrandSelectField'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { Field, InputField } from 'shared/ui/components/form'
import { ozonproxyApi, pimApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { snackActions } from 'shared/lib/react/snackbar'
import { downloadFile, getServerReadableDateTime, toMinDateTime } from 'shared/lib/utils'
import { InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { history } from 'app/providers/with-router'
import { BulkOperationsResultModal } from 'widgets/product'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { Accordion } from 'shared/ui/components/Accordion'
import { MarketplaceFormProps } from 'features/product/bulkOperations/bulkProductCreation/config'

import { getSyncAvailability } from '../../lib/getSyncAvailability'

const DEFAULT_FILE_NAME = 'template.xlsx'

export const getOptionFromCategory = (
  category: ozonproxyApi.model.Category
): Option<ozonproxyApi.model.Category> => ({
  label: `${category.categoryName}/${category.typeName}`,
  // @ts-ignore
  value: category.typeId,
  descriptionCategoryId: category.descriptionCategoryId
})

const currentDate = new Date()
const DEFAULT_MIN_DATE = new Date(currentDate).setMonth(
  toMinDateTime(currentDate).getMonth() - 1
)
const defaultCalendarMinDate = getServerReadableDateTime(DEFAULT_MIN_DATE)
const defaultCalendarMaxDate = getServerReadableDateTime(new Date())

export const MarketplaceFormOzon: React.FC<MarketplaceFormProps> = ({
  companymarketplaceId,
}) => {
  const [datePeriodOpen, setDatePeriodOpen] = useState(false) 
  const { mutate: uploadMutation, isLoading: isUploading } =
    pimApi.products.ozon.useUploadOzonProductBulkEditFile()
  const { mutate: downloadMutation, isLoading: isDownloading } =
    pimApi.products.ozon.useDownloadOzonProductBulkEditTemplateFile()

  const categoryIdField = useField('categoryOzon').input.value
  const brandIdField = useField('brandId').input.value
  const dateFromField = useField('dateFrom')
  const dateFrom = useField('dateFrom').input.value
  const dateToField = useField('dateTo')
  const dateTo = useField('dateTo').input.value

  const download = () => {
    downloadMutation(
      {
        companymarketplaceId,
        brandId: brandIdField?.value,
        categoryId: categoryIdField?.value,
        parentCategoryId: categoryIdField?.descriptionCategoryId,
        ...(datePeriodOpen && dateFrom ? { dateFrom } : {}),
        ...(datePeriodOpen && dateTo ? { dateTo } : {}),
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName =
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ) || DEFAULT_FILE_NAME

          downloadFile(file, decodeURIComponent(fileName.replace(/%2C/g, ',')))
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
        companymarketplaceId,
        categoryId: categoryIdField?.value,
        parentCategoryId: categoryIdField?.descriptionCategoryId,
        ...(brandIdField?.value ? { brandId: brandIdField?.value } : {}),
      },
      {
        onSuccess: (
          data: AxiosResponse<pimApi.products.ImportMetadata>
        ) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={getSyncAvailability(data?.data.responseType)}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const fetchCatalog = useCallback<
    (steach: string) => Promise<Array<Option<ozonproxyApi.model.Category>>>
      >(
      (search: string) =>
        ozonproxyApi
          .fetchCategories(encodeURIComponent(search), companymarketplaceId)
          .then((data) => data.data.map(getOptionFromCategory)),
      [companymarketplaceId]
      )

  const handleDatePeriodExpand = () => {
    dateFromField.input.onChange(defaultCalendarMinDate)
    dateToField.input.onChange(defaultCalendarMaxDate)
    setDatePeriodOpen(true)
  }
  const handleDatePeriodClose = () => {
    dateFromField.input.onChange(null)
    dateToField.input.onChange(null)
    setDatePeriodOpen(false)
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box mb={1}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <InputLabel label="Категория" />
          <InputWrapper>
            <Field
              name="categoryOzon"
              component={AutocompleteCatalogSearchField}
              fetchCatalog={fetchCatalog}
              disableClearable={false}
              required={true}
              placeholder="Категория"
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={6}>
          <InputLabel label="Бренд" required={false} />
          <InputWrapper>
            <Field
              name="brandId"
              label="Бренд"
              errorAfterTouch={true}
              component={OzonBrandSelectField}
              disableClearable={false}
              companymarketplaceId={companymarketplaceId}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={12}>
          <Accordion
            title="Выберите продукты созданные за период"
            onOpen={handleDatePeriodExpand}
            onClose={handleDatePeriodClose}
          >
            <Grid container={true} spacing={2} mb={2}>
              <Grid item={true} xs={2} pb={0}>
                <Field
                  name="dateFrom"
                  label="Начало периода"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={InputField}
                />
              </Grid>
              <Grid item={true} xs={2} pb={0}>
                <Field
                  name="dateTo"
                  label="Конец периода"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={InputField}
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
        <Grid item={true} xs={12} p={0}>
          <BulkFileActionsFooter
            download={download}
            upload={upload}
            isUploading={isUploading}
            isDownloading={isDownloading}
            onGoBack={handleGoBack}
            disabled={Boolean(!categoryIdField)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
