import { Box, Button, DialogActions, Grid, Tabs } from '@mui/material'
import { generatePath, useHistory, useParams } from 'react-router'
import { pimApi } from 'shared/api'
import {
  FieldWithCount,
  InputField,
  InputLabel,
  MaskedInputField,
  SelectFieldWithAutoComplete,
} from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { Field, useField } from 'react-final-form'
import { Accordion } from 'shared/ui/components/Accordion'
import { StyledAccordionBox } from 'features/product/WB/PriceChange/ui/PriceChangeModal/ui/styled'
import { useMemo } from 'react'
import { getOptions } from 'features/product/WB/PriceChange/ui/PriceChangeModal/ui/PriceChangeModalFormRender'
import { PriceCard } from 'entities/pim/price'
import HistoryIcon from '@mui/icons-material/History'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import { LoadingButton } from '@mui/lab'
import { PriceCardWithMenu } from 'features/product/OZON/PriceChange/ui/PriceCardWithMenu'
import { PRODUCT_PRICE_HISTORY } from 'shared/config'
import { useOzonProductContext } from 'entities/pim/product/model/ozon/useOzonProductContext'
import { isNil } from 'shared/lib/checkers'

import { H1PriceControl, IconWrap, TabsWrap } from './styled'

export function PriceControl({ values, handleSubmit, form, isCreating }) {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const productId = parseInt(id, 10)
  const { ozonProductQuery } = useOzonProductContext()

  const {
    data: priceChangeReasonsQuery,
    isLoading: priceChangeReasonsLoading,
  } = pimApi.dictionaries.usePriceChangeReasonsQuery()
  const priceChangeReasonOptionList = useMemo(
    () => priceChangeReasonsQuery?.map(getOptions) || [],
    [priceChangeReasonsQuery]
  )

  const curDate = new Date()

  const defaultDateValue = useMemo(
    () =>
      new Date(
        new Date(curDate.setHours(24, 0, 0, 0)).getTime() -
          curDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 16),
    []
  )

  const handleCommentsClose = () => commentsField.input.onChange(null)
  const handleChangeDateExpand = () =>
    changeDateField.input.onChange(defaultDateValue)
  const handleChangeDateClose = () => changeDateField.input.onChange(null)
  const handleRevertDateExpand = () =>
    revertDateField.input.onChange(defaultDateValue)

  const handleRevertDateClose = () => revertDateField.input.onChange(null)

  const changeDateField = useField('changeDate')
  const revertDateField = useField('revertDate')
  const commentsField = useField('comments')

  const showPriceHistory = () => {
    const path = `${generatePath(PRODUCT_PRICE_HISTORY, { id: productId })}`
    history.push(path)
  }

  const isPriceChangeable = !!ozonProductQuery?.data?.price

  return (
    <form onSubmit={() => {}}>
      <Box>
        <H1PriceControl>
          Управление ценами
          <IconWrap>
            <HistoryIcon
              fontSize="small"
              color="disabled"
              onClick={showPriceHistory}
            />
          </IconWrap>
        </H1PriceControl>

        <TabsWrap>
          <Tabs variant="scrollable" scrollButtons="auto">
            {ozonProductQuery.data ? (
              <>
                <PriceCard
                  key={productId}
                  title="Текущая цена"
                  price={ozonProductQuery.data?.price}
                  oldPrice={ozonProductQuery.data?.oldPrice}
                />

                {(values.newPrice || values.newOldPrice) && (
                  <PriceCard
                    title="Новая цена"
                    price={values?.newPrice || ozonProductQuery?.data?.price}
                    oldPrice={
                      values?.newOldPrice || ozonProductQuery?.data?.oldPrice
                    }
                  />
                )}

                {isNotEmptyArray(ozonProductQuery.data?.futurePrices) &&
                  ozonProductQuery.data?.futurePrices &&
                  ozonProductQuery.data?.futurePrices.map(
                    ({ changeDate, price, oldPrice, ...rest }) => (
                      <PriceCardWithMenu
                        key={rest.id}
                        productId={productId}
                        price={price}
                        oldPrice={oldPrice}
                        title={`Цена ${
                          changeDate.split('-')[2].split('T')[0]
                        } ${
                          DateAppearanceSettings[changeDate.split('-')[1]].month
                        }`}
                        {...rest}
                      />
                    )
                  )}
              </>
            ) : null}
          </Tabs>
        </TabsWrap>

        <Box sx={{ maxWidth: '440px', paddingTop: '16px' }}>
          <InputLabel label="Цена" required={false} />
          <Grid spacing={2} container={true}>
            <Grid
              sx={{
                marginBottom: '16px',
              }}
              item={true}
              xs={6}
            >
              <InputWrapper>
                <Field
                  fullWidth={true}
                  sx={{
                    '& .MuiInputBase-inputAdornedEnd': {
                      paddingRight: '8px',
                    },
                  }}
                  name="newPrice"
                  label="Цена на маркетплейсе"
                  autoComplete="off"
                  type="number"
                  component={MaskedInputField}
                  disabled={false}
                  scale={0}
                  min={0}
                />
              </InputWrapper>
            </Grid>
            <Grid item={true} xs={6}>
              <InputWrapper>
                <Field
                  fullWidth={true}
                  sx={{
                    '& .MuiInputBase-inputAdornedEnd': {
                      paddingRight: '8px',
                    },
                  }}
                  name="newOldPrice"
                  label="Цена без скидки"
                  autoComplete="off"
                  type="number"
                  component={MaskedInputField}
                  disabled={false}
                  scale={0}
                  min={0}
                />
              </InputWrapper>
            </Grid>
          </Grid>
          <Box>
            <Grid item={true} xs={12}>
              <InputWrapper>
                <Field
                  name="pricechangereasonId"
                  id="pricechangereasonId"
                  label={`Причина изменения цены ${isPriceChangeable ? '' : '*'}`}
                  required={true}
                  errorAfterTouch={true}
                  component={SelectFieldWithAutoComplete}
                  fullWidth={true}
                  options={priceChangeReasonOptionList}
                  isLoading={priceChangeReasonsLoading}
                />
              </InputWrapper>
            </Grid>
            <Grid item={true} xs={12}>
              <Accordion
                title="Оставить комментарий"
                onClose={handleCommentsClose}
              >
                <Grid item={true} xs={12} pb={0}>
                  <FieldWithCount>
                    <Field
                      name="comments"
                      component={InputField}
                      multiline={true}
                      fullWidth={true}
                      minRows={1}
                      maxRows={10}
                      max={255}
                    />
                  </FieldWithCount>
                </Grid>
              </Accordion>
            </Grid>
            <Grid item={true} xs={12}>
              <StyledAccordionBox>
                <Accordion
                  key="first-acc"
                  title="Запланировать время изменения"
                  onOpen={handleChangeDateExpand}
                  onClose={handleChangeDateClose}
                >
                  <Field
                    name="changeDate"
                    label="Дата время"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    component={InputField}
                  />
                </Accordion>
                <Accordion
                  title="Вернуться к исходной цене"
                  onOpen={handleRevertDateExpand}
                  onClose={handleRevertDateClose}
                >
                  <Field
                    name="revertDate"
                    label="Дата время"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    component={InputField}
                  />
                </Accordion>
              </StyledAccordionBox>
            </Grid>
          </Box>

          {values.newPrice || values.newDiscount ? (
            <DialogActions
              sx={{
                marginTop: '16px',
                marginBottom: '12px',
                padding: 0,
                '& .MuiButton-root': {
                  height: '32px',
                },
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  form.restart()
                }}
              >
                ОТМЕНА
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  isPriceChangeable
                    ? (!values.newPrice && !values.newDiscount) || isNil(values.pricechangereasonId)
                    : !values.newPrice
                }
                loading={isCreating}
              >
                {isPriceChangeable ? 'СОХРАНИТЬ' : 'УСТАНОВИТЬ'}
              </LoadingButton>
            </DialogActions>
          ) : (
            <div style={{ height: '60px' }} />
          )}
        </Box>
      </Box>
    </form>
  )
}
